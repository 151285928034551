<template>
  <div v-if="location">
    <modal-edit-data
      v-if="showEditLocationData"
      :showModal="showEditLocationData"
      :onClickCancel="hideModal"
    />

    <ui-section-header :showEdit="true" :editFunction="setShowEditLocationData">
      <template v-slot:title>Basic data</template>
    </ui-section-header>

    <table class="table is-narrow is-fullwidth is-hoverable">
      <tbody>
        <tr>
          <td>Name</td>
          <td class="has-text-right">{{ location.Name }}</td>
        </tr>
        <tr>
          <td>Address</td>
          <td class="has-text-right">{{ location.Address }}</td>
        </tr>
        <tr>
          <td>Addition</td>
          <td class="has-text-right">{{ location.Addition }}</td>
        </tr>
        <tr>
          <td>Postal code</td>
          <td class="has-text-right">{{ location.Zipcode }}</td>
        </tr>
        <tr>
          <td>City</td>
          <td class="has-text-right">{{ location.City }}</td>
        </tr>
        <tr>
          <td>Country</td>
          <td class="has-text-right"></td>
        </tr>
        <tr>
          <td>Phone</td>
          <td class="has-text-right">{{ location.PhoneNumber }}</td>
        </tr>
        <tr>
          <td>Email</td>
          <td class="has-text-right">{{ location.Email }}</td>
        </tr>
        <tr>
          <td>Website</td>
          <td class="has-text-right">{{ location.Url }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'

const ModalEditLocationData = () =>
  import('@/components/Locations/ModalEditLocationData')

export default {
  components: {
    'modal-edit-data': ModalEditLocationData,
  },

  props: {},

  data() {
    return {
      showEditLocationData: false,
    }
  },

  computed: {
    ...mapState('locationStore', ['location', 'locationState']),
  },

  created() {},

  methods: {
    setShowEditLocationData() {
      this.showEditLocationData = true
    },

    hideModal() {
      this.showEditLocationData = false
    },
  },
}
</script>

<style></style>
